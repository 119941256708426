import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './Pages/LandingPage/LandingPage';
import LoginPage from './Pages/LoginPage/LoginPage';
import HomePage from './Pages/HomePage/HomePage';
import SignUp from './Pages/SignupPage/SignupPage';
import ShopPage from './Pages/ShopPage/ShopPage';
import MapPage from './Pages/Map/MapPage';
import SettingsPage from './Pages/SettingsPage/SettingsPage';
import ShopOwnerPage from './Pages/ShopOwnerPage/ShopOwnerPage';
import AdminPage from './Pages/AdminPage/AdminPage';
import AccountPage from './Pages/AccountPage/AccountPage';
import ShopHoursPage from './Pages/ShopOwnerPage/ShopHoursPage/ShopHoursPage';
import ShopServicesPage from './Pages/ShopOwnerPage/ShopServicesPage/ShopServicesPage';
import './App.css';


const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const center = { lat: 40.7128, lng: -74.0060 }; // New York City
  const zoom = 12;
  const markers = [
    { lat: 40.7484, lng: -73.9857 }, // Empire State Building
    { lat: 40.7580, lng: -73.9855 }, // Times Square
  ];

  return (
    <div className="App">
    <Router>
        <Routes>
          {/* { isLoggedIn ? <Route path="/home" element={<HomePage />} /> : <Route path="/" element={<LoginPage onLogin={handleLogin} />} />} */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/shop/*" element={<ShopPage />} />
          <Route path="/shopowner" element={<ShopOwnerPage />} />
          <Route path="/shopowner/hours" element={<ShopHoursPage />} />
          <Route path="/shopowner/services" element={<ShopServicesPage />} />
          <Route path="/map" element={<MapPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/account" element={<AccountPage />} />
          {/* <Route path="/map" element={<ProtectedRoute><MapPage /></ProtectedRoute>} /> */}
            
            {/* {isLoggedIn ? (
              <HomePage />
            ) : (
              <LoginPage onLogin={handleLogin} />
            )} */}
          {/* <Route path="/calendar">
            {isLoggedIn ? (
              <CalendarPage />
            ) : (
              <LoginPage onLogin={handleLogin} />
            )}
          </Route> */}
        </Routes>
    </Router>
    </div>
  );
};

export default App;
