import React from 'react';
import { Card, CardMedia, Typography, Box, Divider } from '@mui/material';
import { FaPlus } from "react-icons/fa6";


const OvalCardaddService = ({ title, imageUrl, price, duration, onClick }) => {
  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'center', // Horizontal centering
        alignItems: 'center',     // Vertical centering
        borderRadius: '50px',
        overflow: 'hidden',
        maxWidth: '400px',
        height: '80px',
        margin: '20px auto',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        backgroundColor: '#87878740',
      }}
      onClick={onClick}
    >
      <FaPlus 
        size={40}  // Increase icon size
        style={{  // Center the icon explicitly
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }} 
      />
    </Card>
  );
};

export default OvalCardaddService;