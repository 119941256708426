import React, { useEffect, useState } from 'react';
import ResponsiveAppBar from '../../../components/appbar/Appbar';
import ShopServices from '../../../components/shopservices/ShopServices';
import { Service } from '../../../resources/shopsDB/ShopsDB';
import { auth } from '../../../components/auth/firebase';
import { Grid } from '@mui/material';
import IconRow from '../../../components/iconrow/IconRow';
import { getPublicShopInfo, ShopInfo } from '../../../resources/shopsDB/ShopsDB';
import '../../../App.css';
import ShopCalendar from '../../../components/shopCalendar/ShopCalendar';
import OvalCard from '../../../components/shopservices/OvalCard';
import { removeShopService } from '../../../resources/shopsDB/ShopsDB';




const ShopServicesPage: React.FC = () => {
    const [selectedService, setSelectedService] = useState<Service | null>(null);
    const [shopInfo, setShopInfo] = useState<ShopInfo | null>(null);


    const getShopInfo = async () => {
        const shopData: ShopInfo = await getPublicShopInfo(null)
        setShopInfo(shopData);
    }

    const removeShopServiceWrapper = async (service: Service) => {
        const removeEvent = window.confirm(`Are you sure you want to delete ${service.name}?`)
        if (removeEvent) {
            await removeShopService(service);
            void getShopInfo();
        }
    }
    
    
    
    useEffect(() => {
        void getShopInfo();
    }, []);

    return (
        <div>
            <ResponsiveAppBar />
            <ShopServices shopInfo={shopInfo} selectedService={selectedService} onClick={(service)=>{removeShopServiceWrapper(service)}} addService={true}/>
            {/* <OvalCard key={99999} title={''} imageUrl={''} price={''} duration={''} highLight={selectedService && selectedService.name === ''} onClick={()=>{setSelectedService(null)}}/> */}
        </div>
    )
}


export default ShopServicesPage;