import React, { useState, useEffect, useRef, useCallback  }  from 'react';
import ResponsiveAppBar from '../../components/appbar/Appbar';
import { isMobile } from 'react-device-detect';
import { loadNailSalondata, NailSalonsGeo } from '../Map/nail_salons';
import { fetchBucketImage } from '../../components/auth/Bucketimages';
import '../../App.css';


const SettingsPage: React.FC = () => {

  // if (!isMobile){
  return (
      <div className="settingspage-container">
        <ResponsiveAppBar />
      </div>
  );
};

export default SettingsPage;
