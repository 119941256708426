import * as React from 'react';
import { Grid, IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import InstagramImage from './icons8-instagram-48.png';
import BlackandWhiteInstagramImage from './black&white_icons8-instagram-48.png'
import FacebookImage from './icons8-facebook-48.png';
import BlackandWhiteFacebookImage from './black&white_icons8-facebook-48.png';
import EmailImage from './icons8-email-48.png'
import BlackandWhiteEmailImage from './black&white_icons8-email-48.png'
import { ShopInfo } from '../../resources/shopsDB/ShopsDB';
import ImageMenuButton from './imagemenubutton/ImageMenuButton';


interface ShopInfoComponentProps {
        shopInfo: ShopInfo;
}

const IconRow: React.FC<ShopInfoComponentProps> = ({shopInfo}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    if (shopInfo === null) {
        return (
            <div>
                <p>Loading...</p>
            </div>
        );
    }

    return (
        <Grid container flexDirection='row' justifyContent="center" sx={{background: "#fff8f5"}}>
            <Grid item>
            {((shopInfo.instagram === '') || (shopInfo.instagram === undefined)) ? (<div><img src={BlackandWhiteInstagramImage} alt="Instagram logo" /></div>) : (<a href={shopInfo.instagram} target="_blank" rel="noreferrer">
                <img src={InstagramImage} alt="Instagram logo" />
            </a>)}
            </Grid>
            <Grid item>
            {((shopInfo.facebook === '') || (shopInfo.facebook === undefined)) ? (<div><img src={BlackandWhiteFacebookImage} alt="Facebook logo" /></div>) : (<a href={shopInfo.facebook} target="_blank" rel="noreferrer">
                <img src={FacebookImage} alt="Facebook logo" />
            </a>)}
            </Grid>
            <Grid item>
            {((shopInfo.email === '') || (shopInfo.email === undefined)) ? (<div><img src={BlackandWhiteEmailImage} alt="Email logo" /></div>) : (<a href={shopInfo.email} target="_blank" rel="noreferrer">
                <img src={EmailImage} alt="Email logo" />
            </a>)}
            </Grid>
            <Grid item>
                <ImageMenuButton  shopProps={shopInfo}/>
            </Grid>
        </Grid>
    );
}

export default IconRow;