import { db } from "../../components/auth/firebase";
import { doc, setDoc, getDoc, getDocs, collection, updateDoc } from 'firebase/firestore';
import { auth } from "../../components/auth/firebase";
import { getUserInfo } from "../usersDB/UsersDB";
import { getAppointmentInfo } from "../appointmentsDB/AppointmentsDB";


export interface ShopInfo {
    address: string;
    email: string;
    facebook: string;
    geometry: [];
    header_image: string;
    instagram: string;
    name: string;
    phone: string;
    ShopServices: Service[] | null;
    Shopweeklyschedule: [];
    ShopData: ShopSubData | null;
}

export interface ShopSubData    {
    appointments: [];
    shopOwner: string;

}

// export interface ShopServicesData {
//     services: Service[] | null;
// }

export interface Service {
    description: string;
    image: string;
    name: string;
    price: number;
    shop_id: string;
    time_length: number;
}


// // Function to store user info
export async function storeShopInfo(userId: string, userInfo: object): Promise<void> {
    try {
        await setDoc(doc(db, "users", userId), userInfo);
        console.log("User information successfully stored!");
    } catch (error) {
        console.error("Error storing user information: ", error);
    }
    }


export async function storeShopWeeklySchedule(weeklySchedule: object []): Promise<void> {
    try {
        const userData = await getUserInfo()
        await updateDoc(doc(db, "shops", userData.shopOwned), {
            shopweeklyschedule: JSON.stringify(weeklySchedule)
        });
        console.log("Shop Hours updated successfully!");
    } catch (error) {
        console.error("Error updating shop hours: ", error);
    }
}

export async function addShopService(service: Service): Promise<void> {
    try {
        const userData = await getUserInfo()
        service.shop_id = userData.shopOwned
        const docSnap = await getDoc(doc(db, "shops", userData.shopOwned)); 
        let ShopInfo = docSnap.data()
        ShopInfo.shop_services.push(JSON.stringify(service))
        await updateShopInfo(userData.shopOwned, ShopInfo as ShopInfo)
        console.log("Service successfully added!");
    } catch (error) {
        console.error("Error adding service: ", error);
    }
}

export async function removeShopService(service: Service): Promise<void> {
    try {
        const userData = await getUserInfo()
        const docSnap = await getDoc(doc(db, "shops", userData.shopOwned)); 
        let ShopInfo = docSnap.data()
        let index = ShopInfo.shop_services.indexOf(JSON.stringify(service))
        ShopInfo.shop_services.splice(index, 1)
        updateShopInfo(userData.shopOwned, ShopInfo as ShopInfo)
        console.log("Service successfully removed!");
    } catch (error) {
        console.error("Error removing service: ", error);
    }
}

export async function getShopServicesCollection(ShopId: string): Promise<Service[] | null> {
    try {
        const subcollectionRef = collection(db, 'shops', ShopId, 'ShopServices');
        const subcollectionSnapshot = await getDocs(subcollectionRef);
        const subcollectionData = subcollectionSnapshot.docs.map(doc => ({
        ...doc.data()
        }));

        if (subcollectionData.length > 0){
            const shopSubData: Service[] = subcollectionData.map(doc => ({
                description: doc.description,
                image: doc.image,
                name: doc.name,
                price: doc.price,
                shop_id: doc.shop_id,
                time_length: doc.time_length
            }))
            return shopSubData;
        }
        console.error("No data found");
        return [];
    } catch (error) {
        console.error("Error getting shop services collection information: ", error);
        return null;
    }
}

// Function to get user info
export async function getPublicShopInfo(ShopId: string | null): Promise<ShopInfo | null> {
    try {
        if (auth.currentUser) {
            var docSnap
            if (ShopId !== null) {
                docSnap = await getDoc(doc(db, "shops", ShopId));                
            }
            else {
                const userData = await getUserInfo()
                docSnap = await getDoc(doc(db, "shops", userData.shopOwned));

            }
            return await {
                address: docSnap.data().address,
                email: docSnap.data().email,
                facebook: docSnap.data().facebook,
                geometry: docSnap.data().geometry,
                header_image: docSnap.data().header_image,
                instagram: docSnap.data().instagram,
                name: docSnap.data().name,
                phone: docSnap.data().phone,
                ShopServices: docSnap.data().shop_services ? docSnap.data().shop_services.map(doc => (JSON.parse(doc))) : [],
                Shopweeklyschedule: docSnap.data().shopweeklyschedule ? JSON.parse(docSnap.data().shopweeklyschedule) : [],
                ShopData: {
                    appointments: [],
                    shopOwner: ""
                }
            }
        }
        else {
            alert('Please login or sign up')
            return null};
    } catch (error) {
        console.error("Error getting shop information: ", error);
        return null;
    }
}


export async function updateShopInfo(ShopId: string, ShopInfo: ShopInfo): Promise<void> {
    try {
        const docSnap = await getDoc(doc(db, "shops", ShopId));
        await updateDoc(docSnap.ref, ShopInfo as object);
        console.log("Shop information successfully updated!");
    } catch (error) {
        console.error("Error updating shop information: ", error);
    }
}

export async function getShopWeeklySchedule(ShopId: string): Promise<[]> {
    try {
        const docSnap = await getDoc(doc(db, "shops", ShopId));
        return JSON.parse(docSnap.data().shopweeklyschedule);
    } catch (error) {
        console.error("Error getting shop information: ", error);
        return [];
    }
}

