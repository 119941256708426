import React, { useEffect, useState, useRef } from 'react';
import { Calendar, formatDate } from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'
import { auth } from '../auth/firebase';
import momentPlugin from '@fullcalendar/moment';
import { Appointment, createOwnerBlockHours, getAppointmentInfo, deleteAppointment } from '../../resources/appointmentsDB/AppointmentsDB';
import './ShopOwnerCalendar.css'
import { getPublicShopInfo, getShopWeeklySchedule } from '../../resources/shopsDB/ShopsDB';
import rrulePlugin from '@fullcalendar/rrule';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { bookAppointment } from '../../resources/appointmentsDB/AppointmentsDB';


interface ShopOwnerCalendarProps {
  shopId: string
  DeleteAppointments: boolean;
  // Not: shopData: string
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});




const ShopOwnerCalendar: React.FC<ShopOwnerCalendarProps> = ({shopId, DeleteAppointments}) => {
  const slotGranularity = 10
  const calendarRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [focusDate, setFocusDate] = useState(null);
  const [currentEvents, setCurrentEvents] = useState<Appointment[]>([]);

  const [confirmWindowOpen, setConfirmWindowOpen] = useState(false);
  const [confirmWindowMessage, setConfirmWindowMessage] = useState('');


  const [nameFieldValue, setnameFieldValue] = useState('');
  const [telephoneFieldValue, settelephoneFieldValue] = useState('');
  const [serviceFieldValue, setserviceFieldValue] = useState('');
  const [requestFieldValue, setrequestFieldValue] = useState('');

  const [selectedEvent, setSelectedEvent] = useState<any | null>(null);
  
  



  useEffect(() => {
    fetchEvents();
  }, []);

  

  const handleNameFieldChange = (event) => {
    setnameFieldValue(event.target.value);
  };

  const handleTelephoneFieldChange = (event) => {
    settelephoneFieldValue(event.target.value);
  };

  const handleServiceFieldChange = (event) => {
    setserviceFieldValue(event.target.value);
  };

  const handleRequestFieldChange = (event) => {
    setrequestFieldValue(event.target.value);
  };


  useEffect(() => {
    if (focusDate && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(focusDate);
      setFocusDate(null); // Reset focus date after navigating
    }
  }, [focusDate]);

  const fetchEvents = async () => {
    await setIsLoading(true);
    // const shopHours = await getShopAvailableHours(shopId)
    const privateAppointments = await getAppointmentInfo(shopId, true)
    var events_list: Appointment[] = await getShopWeeklySchedule(shopId)
    events_list.forEach((event) => {
      event['display'] = 'background'   
    })
    // events_list.push(my_event)
    events_list.push(...privateAppointments)
    // const eventIncrements = getintervalevents(publicAppointments, events_list)
    // const events_list = convertWeeklyScheduletoEvents(shopweeklyschedule)
    await setCurrentEvents(events_list)
    await setIsLoading(false);
  }

  const confirmAppointment = async () => {
    setConfirmWindowOpen(false)
    // const endTimeDate = GetEndAppointmentDate(selectedEvent.date, selectedService.time_length)
    const shopInfo = await getPublicShopInfo(null)

    const client_id = auth.currentUser.uid

    await bookAppointment({
        title: shopInfo.name,
        start: selectedEvent.start,
        end: selectedEvent.end,
        shop_id: shopId,
        client_id: client_id,
      },
      {
        service_name: serviceFieldValue,
        client_name: nameFieldValue,
        client_phone: telephoneFieldValue,
        client_requests: requestFieldValue,
      }
    )
    await fetchEvents()
    setFocusDate(selectedEvent.start);
  }

  const handleEventDidMount = (info) => {
    if (info.view.type === 'dayGridMonth') {
      info.el.style.display = 'none';
    }
  };
  

  // Adds color coding to the day cells based on the availability of events
  const dayCellDidMount = (arg) => {
    const dateNumber = arg.el.querySelector('.fc-daygrid-day-number');
    if (dateNumber) {
      dateNumber.style.pointerEvents = 'none';
      dateNumber.style.cursor = 'default';
    }
    else {
      return
    }
    const { date, el } = arg;

    if (currentEvents.length > 0) {
      const allAvailable = currentEvents.every(event => (event as any).title! == 'available');
      const someAvailable = currentEvents.some(event => (event as any).title! == 'available');

      if (allAvailable) {
        el.classList.add('fc-day-all-available');
      } else if (someAvailable) {
        el.classList.add('fc-day-some-available');
      } else {
         el.classList.add('fc-day-none-available');
      }
    }
  };


  /* handles how the calendar should behave when a date is selected
  if the view is month, change to day view
  if the hours are selected create an event and add it to the database
  */
  const handleDateSelect = async (selectInfo: any) => {
    if (selectInfo.view.type === 'dayGridMonth') {
      let calendarApi = selectInfo.view.calendar
      calendarApi.changeView('timeGridDay', selectInfo.startStr);
      return

    }
    if (selectInfo.dateStr) {
      return
    }

    const calendarEvents =  selectInfo.view.calendar.getEvents()

    
    // let confirmSlot = window.confirm('Would you like to block hours between ' + selectInfo.startStr.split('T')[1].split('+')[0].slice(0,-3) + ' to ' + selectInfo.endStr.split('T')[1].split('+')[0].slice(0,-3))
    let calendarApi = selectInfo.view.calendar
    

    calendarApi.unselect() // clear date selection

    setSelectedEvent(selectInfo)
    setConfirmWindowOpen(true)
    // if (!confirmSlot) {
    //   return
    // }

    // // get difference in milliseconds
    // var appointmentLength = (new Date(selectInfo.endStr).getTime() - new Date(selectInfo.startStr).getTime())
    // // convert to hours
    // appointmentLength = appointmentLength / 1000 / 60 / 60
    // // convert to number of 30 minute blocks
    // appointmentLength = appointmentLength * 2

    // const shopInfo = await getPublicShopInfo(null)

    // await createOwnerBlockHours({
    //   title: shopInfo.name,
    //   start: new Date(selectInfo.startStr),
    //   end: new Date(selectInfo.endStr),
    //   shop_id: shopId,
    //   status: 'unavailable',
    // },
    // {
    //   service_name: '',
    //   client_name: 'Blocked Hours',
    //   client_phone: '',
    //   client_requests: '',
    // })
    // await fetchEvents()
    // setFocusDate(selectInfo.start);

  }



  const handleEventClick = async (clickInfo: any) => {
    let calendarApi = clickInfo.view.calendar
    if (calendarApi.view.type === 'dayGridMonth') {
      return
    }
    if (DeleteAppointments === false) {
      return
    }
    const removeEvent = window.confirm(`Are you sure you want to delete the event ${clickInfo.event.startStr.split('T')[1].split('+')[0].substr(0, 5)}-${clickInfo.event.endStr.split('T')[1].split('+')[0].substr(0, 5)}?`)
    if (removeEvent) {
      await deleteAppointment(clickInfo.event.id)
      await fetchEvents()
      setFocusDate(clickInfo.event.start);
    }
    
  }





  const renderEventContent = (eventInfo: any) => {
    let calendarApi = eventInfo.view.calendar
    if (calendarApi.view.type === 'dayGridMonth') {
      return (
        null 
      )
    }
    else{
    return (
      <>
        <b>{eventInfo.timeText}</b> 
        <br />
        <i>{eventInfo.event.extendedProps.client_name}</i>
        <br />
        <i>{eventInfo.event.extendedProps.client_phone}</i>
        <br />
        <i>{eventInfo.event.extendedProps.service_name}</i>
      </>
    )
    }
  }


  if (isLoading) {
    return <div>Loading events...</div>;
  }
    return (
      <div className='lilac-calendar'>
        <React.Fragment>
          <Dialog
            open={confirmWindowOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => { setConfirmWindowOpen(false) }}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Book an Appointment"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
              {confirmWindowMessage}
              </DialogContentText>
              <TextField
                autoFocus
                fullWidth 
                margin="dense"
                id="name"
                name="name"
                value={nameFieldValue}
                onChange={handleNameFieldChange}
                label="שם לקוח"
                variant="outlined"
              />
              <TextField
                autoFocus
                fullWidth 
                margin="dense"
                id="telephone"
                name="telephone"
                value={telephoneFieldValue}
                onChange={handleTelephoneFieldChange}
                label="טלפון"
                variant="outlined"
              />
              <TextField
                autoFocus
                fullWidth 
                margin="dense"
                id="service"
                name="service"
                value={serviceFieldValue}
                onChange={handleServiceFieldChange}
                label="טיפול"
                variant="outlined"
              />
              <TextField
                autoFocus
                fullWidth 
                margin="dense"
                id="requests"
                name="requests"
                value={requestFieldValue}
                onChange={handleRequestFieldChange}
                label="הערות"
                variant="outlined"
                multiline
                minRows={2}
              />
            </DialogContent>
            
            <DialogActions>
              <Button onClick={() => { setConfirmWindowOpen(false) }}>Cancel</Button>
              <Button onClick={() => { confirmAppointment() }}>Agree</Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
        <div className='demo-app-main'>
          <FullCalendar
          ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, rrulePlugin, momentPlugin]}
            locale={'en-gb'}
            titleFormat={'D.MM'}
            headerToolbar={{
              left: 'prev,next,today',
              center: 'title',
              right: 'listWeek,dayGridMonth,timeGridDay',
            }}
            initialView='listWeek'
            eventTimeFormat={{
              hour: 'numeric',
              minute: '2-digit',
              meridiem: true
            }}
            editable={true}
            selectable={true}
            // selectAllow={handleSelectAllow} // Allow selection based on custom logic
            selectMirror={true}
            dayMaxEvents={true}
            events={currentEvents}
            slotDuration={{ minutes: slotGranularity }}
            // initialEvents={currentEvents} // alternatively, use the `events` setting to fetch from a feed
            dateClick={handleDateSelect}
            select={handleDateSelect}

            eventContent={renderEventContent} // custom render function
            dayMaxEventRows={0} // This prevents the "+X more" link from appearing
            eventClick={handleEventClick}
            // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
            slotMinTime="08:00:00" // Show only from 8:00 AM
            slotMaxTime="22:00:00" // Show only until 10:00 PM
            slotLabelInterval={{ minutes: slotGranularity }} // Show labels for every hour
            allDaySlot={false}
            navLinks={true}
            selectLongPressDelay={0}
            height="auto"
            aspectRatio={1.35}
            dayCellDidMount={dayCellDidMount}
            eventDidMount={handleEventDidMount}
            eventStartEditable={false}
            eventResizableFromStart={false}
            eventDurationEditable={false}
            // you can update a remote database when these fire:
            // eventAdd={function(){}}
            //eventChange={function(){}}
            // eventRemove={function(){}}
            
          />
        </div>
      </div>
    )

}


  


  

export default ShopOwnerCalendar;