import React, { useEffect, useState, useRef } from 'react';
import { Calendar, formatDate } from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'
import rrulePlugin from '@fullcalendar/rrule'
import './ShopOwnerSettingsCalendar.css'
import { getPublicShopInfo, storeShopWeeklySchedule } from '../../resources/shopsDB/ShopsDB';
import { compareAsc, areIntervalsOverlapping } from 'date-fns';

import { Button, duration } from '@mui/material';



export interface Event {
  id: string,
  client_id: string,
  shop_id: string,
  title: string,
  start: Date,
  end: Date,
  service: string,
  allDay: boolean,
  status: string,
}

interface ShopTimeSlot {
  title: string,
  duration: string,
  allDay: boolean,
  rrule: {
    freq: string,
    dtstart: string,
    byweekday: string[]
  }
}


const ShopOwnerSettingsCalendar: React.FC = () => {
  const slotGranularity = 10
  const calendarRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [focusDate, setFocusDate] = useState(null);
  const [shopHours, setShopHours] = useState<ShopTimeSlot[] | null>([]);
  
  



  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    mergeEvents()
  }, [shopHours]);


  useEffect(() => {
    if (focusDate && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(focusDate);
      setFocusDate(null); // Reset focus date after navigating
    }
  }, [focusDate]);

  const fetchEvents = async () => {
    await setIsLoading(true);
    const shopInfo = await getPublicShopInfo(null)
    await setShopHours(shopInfo.Shopweeklyschedule)
    await setIsLoading(false);
  }

  

  const updateShopHours = async () => {
    await storeShopWeeklySchedule(shopHours)
    // await fetchEvents()
  }

  function ToTimeString(duration) {
    var minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  
    var hoursStr = (hours < 10) ? "0" + hours : hours;
    var minutesStr = (minutes < 10) ? "0" + minutes : minutes;
  
    return hoursStr + ":" + minutesStr;
  }

  function convertTimeStringToTimestamp(timeString) {
    var hours = parseInt(timeString.split(":")[0]);
    var minutes = parseInt(timeString.split(":")[1]);
    return (hours * 60 + minutes) * 60 * 1000;
  }

  /* handles how the calendar should behave when a date is selected
  if the view is month, change to day view
  if the hours are selected create an event and add it to the database
  */
  const handleDateSelect = async (selectInfo: any) => {


    
    // let confirmSlot = window.confirm('Would you like to add available hours between \n' + new Date(selectInfo.startStr).toLocaleString() + '\n to \n' + new Date(selectInfo.endStr).toLocaleString())
    let calendarApi = selectInfo.view.calendar
    

    calendarApi.unselect() // clear date selection
    // if (!confirmSlot) {
    //   return
    // }

    const durationStr = ToTimeString(selectInfo.end - selectInfo.start)
    const day = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'][selectInfo.start.getDay()]


    await setShopHours([...shopHours,{
      title: 'Available',
      duration: durationStr,
      allDay: false,
      rrule: {
        freq: 'weekly',
        dtstart: selectInfo.startStr,
        "byweekday": [day]
      }}]
    )
    

  }



  const handleEventClick = async (clickInfo: any) => {
    const removeEvent = window.confirm(`Are you sure you want to delete the event ${clickInfo.event.startStr.split('T')[1].split('+')[0].substr(0, 5)}-${clickInfo.event.endStr.split('T')[1].split('+')[0].substr(0, 5)}?`)
    if (removeEvent) {
      var filteredShopHours = shopHours.filter((ShopTimeSlot: any) => {
        return ShopTimeSlot['rrule']['dtstart'] !== clickInfo.event.startStr})
      // await deleteAppointment(clickInfo.event.id)
      // await fetchEvents()
      await setShopHours(filteredShopHours)
      await updateShopHours()
      setFocusDate(clickInfo.event.start);
    }
    
  }


  



  const handleEvents = (events: any) => {
    // setCurrentEvents(events)
  }


  const renderEventContent = (eventInfo: any) => {
    return (
      <>
        <b>{eventInfo.timeText}</b> 
        <br />
        <i>{eventInfo.event.title}</i>
        <br />
        <i>{eventInfo.event.extendedProps.status}</i>
        {/* <i> {eventInfo.event.shop_id}</i> */}
      </>
    )
  }

  // TODO: fix this, infinite loop
  const mergeEvents = () => {
    // Sort events by start time
    const sortedEvents = [...shopHours].sort((a, b) => compareAsc(new Date(a.rrule.dtstart), new Date(b.rrule.dtstart)));
    
    var mergeFlag = false;
    const mergedEvents = [];
    let currentEvent = null;
  
    for (const event of sortedEvents) {
      if (!currentEvent) {
        currentEvent = { ...event };
      } else {

        var timeStamp = convertTimeStringToTimestamp(currentEvent.duration)
        var curEventStart = new Date(currentEvent.rrule.dtstart);
        var curEventEnd = new Date(Date.parse(currentEvent.rrule.dtstart) + timeStamp);

        timeStamp = convertTimeStringToTimestamp(event.duration)
        const eventStart = new Date(Date.parse(event.rrule.dtstart) + timeStamp);
        const eventEnd = new Date(Date.parse(event.rrule.dtstart) + timeStamp);

        if (areIntervalsOverlapping(
          { start: new Date(currentEvent.rrule.dtstart), end: new Date(curEventEnd) },
          { start: new Date(event.rrule.dtstart), end: new Date(eventEnd) }
        , {inclusive: true})) {
          var newEnd = new Date(Math.max(curEventEnd.getTime(), eventEnd.getTime()));
          currentEvent.duration = ToTimeString(newEnd.getTime() - new Date(curEventStart).getTime());
          // mergedEvents.push(currentEvent);
          mergeFlag = true;
        } else {
          // No overlap, add current event to result and start a new current event
          mergedEvents.push(currentEvent);
          currentEvent = { ...event };
        }
      }
    }
  
    // Add the last event
    if (currentEvent) {
      mergedEvents.push(currentEvent);
    }
    if (mergeFlag) {
      setShopHours(mergedEvents);
    }
    return;
  };
  

  if (isLoading) {
    return <div>Loading events...</div>;
  }
    return (
      <div className='lilac-calendar'>
        <Button onClick={() => {updateShopHours()}}>Update Hours</Button>
        <div className='demo-app-main'>
          <FullCalendar
          ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, rrulePlugin]}
            // titleFormat={{ year: 'numeric', month: 'short', day: 'numeric' }}
            headerToolbar={{
              left: null,
              center: 'prev,next',
              right: null,
            }}
            initialView='timeGridDay'
            eventTimeFormat={{
              hour: 'numeric',
              minute: '2-digit',
              meridiem: true
            }}

            editable={true}
            selectable={true}
            initialDate={'2024-11-03'}
            validRange={{
                start: '2024-11-03',
                end: '2024-11-10'
              }
            }
            // selectAllow={handleSelectAllow} // Allow selection based on custom logic
            selectMirror={true}
            dayMaxEvents={true}
            events={shopHours}
            slotDuration={{ minutes: slotGranularity }}
            // initialEvents={currentEvents} // alternatively, use the `events` setting to fetch from a feed
            dateClick={handleDateSelect}
            select={handleDateSelect}

            eventContent={renderEventContent} // custom render function
            dayMaxEventRows={0} // This prevents the "+X more" link from appearing
            eventClick={handleEventClick}
            eventsSet={handleEvents} // called after events are initialized/added/changed/removed
            slotMinTime="08:00:00" // Show only from 8:00 AM
            slotMaxTime="22:00:00" // Show only until 10:00 PM
            slotLabelInterval={{ minutes: slotGranularity }} // Show labels for every hour
            allDaySlot={false}
            navLinks={true}
            selectLongPressDelay={0}
            height="auto"
            aspectRatio={1.35}
            // dayCellDidMount={dayCellDidMount}
            // eventDidMount={handleEventDidMount}
            eventStartEditable={false}
            eventResizableFromStart={false}
            eventDurationEditable={false}
            // you can update a remote database when these fire:
            // eventAdd={function(){}}
            //eventChange={function(){}}
            // eventRemove={function(){}}
            
          />
        </div>
      </div>
    )

}


  


  

export default ShopOwnerSettingsCalendar;