import React, { useState } from 'react';
import { Button, ToggleButton, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';


interface ButtonGridProps {
    DeleteAppointments: boolean;
    setDeleteAppointments: (value: boolean) => void;
}


const ButtonGrid: React.FC<ButtonGridProps> = ({DeleteAppointments, setDeleteAppointments}) => {
    const navigate = useNavigate();

    return (
        <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        flexDirection="row"
        flexWrap="wrap"
        sx={{ gap: 2, padding: 2 }}
        >
        <Button
            variant="contained"
            sx={{
            flexGrow: 6,
            minWidth: 100,
            paddingY: 2,
            textAlign: 'center',
            background: 'linear-gradient(90deg, #b62a73 1%, #ffcdb3 100%)',
            }}
            onClick={() => {navigate('/shopowner/hours')}}
        >
            Shop Hours
        </Button>
        <Button
            variant="contained"
            sx={{
            flexGrow: 6,
            minWidth: 100,
            paddingY: 2,
            textAlign: 'center',
            background: 'linear-gradient(90deg, #b62a73 1%, #ffcdb3 100%)',
            }}
            onClick={() => {navigate('/shopowner/services')}}
        >
            Services
        </Button>
        <ToggleButton
        value="check"
        selected={DeleteAppointments}
        color='warning'
        onChange={() => {
            setDeleteAppointments(!DeleteAppointments);
            // setIsToggled(!isToggled);
        }}
>
        Delete Appointments
        </ToggleButton>
        </Box>
    );
    };

export default ButtonGrid;